html
{
	scroll-behavior: smooth;
}


body
{
	background: white;
}


/* Header */
nav.hello-navbar
{
	background: black;
	color: white;
	margin-bottom: 0;
	position: fixed;
	top: 0;
	width: 100%;
}


nav.navbar-default .navbar-nav>li>a
{
	color: white;
}


nav.navbar-default .navbar-nav>li>a:hover,
nav.navbar-default .navbar-nav>li>a:focus,
nav.navbar-default .navbar-nav>li>a:active
{
	background-color: black;
	color: red;
}


@media only screen and (max-width: 767px)
{
	#main-logo1
	{
		display: none;
	}
}


.open-hello-target-navbar
{
	display: block;
	height: 240px;
	left: auto;
	overflow-y: visible;
	padding: 0;
	position: fixed;
	right: 0;
	top: 0;
	visibility: visible;
	z-index: 1;
}


/* Hello There */
.hello-world
{
	height: 750px;
	width: 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
	text-align: center;
}


.hello-padding
{
	padding-bottom: 200px;
	padding-top: 300px;
}


.hello-world-text
{
	font-weight: 700;
	font-size: 75px;
	line-height: 75px;
	margin-bottom: 50px;
	white-space: pre;
}


@media only screen and (max-width: 1248px)
{
	.hello-world-text
	{
		font-size: 62px;
	}
}


@media only screen and (max-width: 998px)
{
	.hello-world-text
	{
		font-size: 42px;
	}
}



@media only screen and (max-width: 768px)
{
	.hello-world-text
	{
		font-size: 22px;
	}
}


@media only screen and (max-width: 320px)
{
	.hello-world-text
	{
		font-size: 18px;
	}
}


@media only screen and (max-width: 280px)
{
	.hello-world-text
	{
		font-size: 16px;
	}
}


/* About Me */
section
{
	padding: 100px 0;
}


#AboutMe
{
	background: #F5F5F5;
}



.hello-me
{
	height: 100%;
	left: -0.0589921%;
	top: 0%;
	width: 100.118%;
}


@media (min-width: 768px)
{
	section
	{
		padding: 150px 0;
	}
}


.introduce-me
{
	color: #333333;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 27px;
	margin-bottom: 16px;
	margin-top: 16px;
}


/* Experience */
.experience-header
{
	font-size: 44px;
	margin-top: 0;
	margin-bottom: 15px;
}


.round-image
{
	border-radius: 50%;
}


.timeline
{
	display: inline-table;
	list-style: none;
	padding: 0;
	position: relative;
	text-align: center;
}


.timeline:before
{
	background-color: lightgray;
	bottom: 0;
	content: "";
	left: 74px;
	margin-left: -1.5px;
	position: absolute;
	top: 0;
	width: 2px;
}


.timeline > li
{
	margin-bottom: 50px;
	min-height: 50px;
	position: relative;
}


.timeline > li:before,
.timeline > li:after
{
	content: " ";
	display: table;
}


.timeline > li:after
{
	clear: both;
}


.timeline > li .timeline-description
{
	float: right;
	padding: 0 20px 0 150px;
	position: relative;
	text-align: left;
	width: 100%;
}


.timeline > li .timeline-description:before
{
	border-left-width: 0;
	border-right-width: 15px;
	left: -15px;
	right: auto;
}


.timeline > li .timeline-description:after
{
	border-left-width: 0;
	border-right-width: 14px;
	left: -14px;
	right: auto;
}


.timeline > li .timeline-image
{
	background-color: white;
	border: 14px solid whitesmoke;
	border-radius: 100%;
	color: white;
	left: 0;
	height: 150px;
	margin-left: 0;
	text-align: center;
	width: 150px;
}


.timeline > li .timeline-image h4
{
	font-size: 10px;
	line-height: 14px;
	margin-top: 12px;
}


.timeline > li.timeline-inverted > .timeline-description
{
	float: right;
	text-align: left;
	padding: 0 20px 0 100px;
}


.timeline > li.timeline-inverted > .timeline-description:before
{
	border-left-width: 0;
	border-right-width: 15px;
	left: -15px;
	right: auto;
}


.timeline > li.timeline-inverted > .timeline-description:after
{
	border-left-width: 0;
	border-right-width: 14px;
	left: -14px;
	right: auto;
}


.timeline > li:last-child
{
	margin-bottom: 0;
}


.timeline .timeline-heading h4
{
	color: inherit;
	margin-top: 0;
}


.timeline .timeline-heading h4.period-text
{
	text-transform: none;
}


.timeline .timeline-body > p,
.timeline .timeline-body > ul
{
	margin-bottom: 0;
}


.period-text
{
	color: #777777;
}


.job-title
{
	color: #BBBBBB;
}


/* Resume */
#Resume
{
	background: #F5F5F5;
}


.resume-header
{
	font-size: 44px;
	margin-top: 0;
	margin-bottom: 15px;
}


img#ResumeImage
{
	height: auto;
	width: 100%;
	max-width: 100%;
}

/* Contact */
.email-me
{
	font-size: 35px;
	font-weight: 500;
	margin: 15px;
	padding: 10px;
	text-align: center;
}


.email-send-button
{
	margin-bottom: 88px;
	text-align: right;
}


.btn-dark
{
	background-color: #343A40;
	border-color: #343A40;
	color: #FFF;
}


.contact-me-error-msg
{
	color: #A94442;
	text-align: right;
}


/* Footer */
footer.hello-footer
{
	background: #FBFBFD;
	border: 1px solid #F5F5F5;
	bottom: 0;
	padding: 25px 0;
	text-align: center;
	position: fixed;
	width: 100%;
}


footer.hello-footer span.footer-copyright
{
	font-size: 18px;
	font-weight: 500;
	line-height: 40px;
	text-transform: none;
}


ul.footer-icon
{
	list-style-type: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	text-align: center;
}

ul.footer-icon li {
	float: right;
}


ul.footer-icon li a
{
	background-color: red;
	border-radius: 100%;
	color: white;
	font-size: 20px;
	display: block;
	height: 40px;
	line-height: 40px;
	margin-right: 20px;
	outline: none;
	width: 40px;
}


ul.footer-icon li a:hover,
ul.footer-icon li a:focus,
ul.footer-icon li a:active
{
	background-color: black;
}


#careerModal
{
	position: relative;
	height: fit-content;

	/* If modal's position needs to be absolute or fixed, specific position needs to be included */
	top: 250px;
	left: 0;
	right: 0;
	bottom: 0;

	/* Centering horizintally */
	margin: auto;

	overflow: auto;
}


.clickable
{
	cursor: pointer;
}


.description
{
	font-size: 18px;
	margin-bottom: 8px;
	white-space: pre-wrap;
}


.company
{
	margin: 0;
}


.period
{
	color: #777777;
	font-size: 18px;
	margin-top: 10px;
	white-space: pre-wrap;
}


.position
{
	color: #BBBBBB;
	font-size: 14px;
	margin-bottom: 0;
}
